@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
html {
  font-family: "Montserrat", sans-serif;
}

table,
th,
td {
  border: 1px solid;
}
